<template>
  <div id="app">
    <!-- <nav class="main-menu">
      <img
        src="https://hfarmafacil.prismafive.com.br/img/farma-facil.0ccbe498.png"
        alt=""
        class="logof"
      />

      <ul>
        <li>
          <a href="#">
            <i class="fa fa-home fa-2x"></i>
            <span class="nav-text"> Dashboard </span>
          </a>
        </li>
        <li class="has-subnav" @click="active">
          <a href="#">               
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="margin: 0 20px"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-box svg-icon feather-icon"
              data-v-0a75af2f=""
            >
              <path
                d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"
                data-v-0a75af2f=""
              ></path>
              <polyline
                points="2.32 6.16 12 11 21.68 6.16"
                data-v-0a75af2f=""
              ></polyline>
              <line
                x1="12"
                y1="22.76"
                x2="12"
                y2="11"
                data-v-0a75af2f=""
              ></line>
            </svg>

            <span class="nav-text">Cadastros</span>
          </a>
          <router-link to="/groupUsers/registerClients">
            <div :class="allItemRegitersStyle" v-show="allItemRegitersActive">
              {{ $t("sectionRegisters.groupUsers") }}
            </div>
          </router-link>
          <router-link to="/typeContact/areaTypesContacts">
            <div :class="typeContactsStyle" v-show="typeContactsActive">
              {{ $t("sectionTypeContact.typeContact") }}
            </div>
          </router-link>
          <router-link to="/siproquim/areaSiproquim">
            <div :class="siproquimsStyle" v-show="siproquimActive">
              Siproquim
            </div>
          </router-link>
          <router-link to="/pbm/areaPbm">
            <div :class="pbmStyle" v-show="pbmActive">
              PBM
            </div>
          </router-link>
          <router-link to="/microComp/areaMicroComp">
            <div :class="microCompStyle" v-show="microCompActive">
              Micro componentes
            </div>
          </router-link>
        </li>
      </ul>

      <ul class="logout">
        <li>
          <a @click="logout">
            <i class="fa fa-power-off fa-2x"></i>
            <span class="nav-text"> Sair </span>
          </a>
        </li>
      </ul>
    </nav> -->
    <div class="container-body">
      <div class="container-body">
        <div>
          <div class="">
            <div class="d-flex flex-column" style="padding-right: 10%"></div>
            <div class="">
              <div class="grid-12 welcome">
                <h2 class="">Olá, bem vindo!</h2>
                <h4 class="">Dashboard</h4>
              </div>

              <div class="grid-3">
                <div class="card" data-v-0a75af2f="">
                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user-plus"
                      data-v-0a75af2f=""
                    >
                      <path
                        d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        data-v-0a75af2f=""
                      ></path>
                      <circle cx="8.5" cy="7" r="4" data-v-0a75af2f=""></circle>
                      <line
                        x1="20"
                        y1="8"
                        x2="20"
                        y2="14"
                        data-v-0a75af2f=""
                      ></line>
                      <line
                        x1="23"
                        y1="11"
                        x2="17"
                        y2="11"
                        data-v-0a75af2f=""
                      ></line>
                    </svg>
                    Usuário
                  </span>
                </div>
              </div>
              <div class="grid-3" data-v-0a75af2f="">
                <a class="" href="/#/grupoProduto/1" data-v-0a75af2f=""
                  ><div class="card" data-v-0a75af2f="">
                    <span data-v-0a75af2f=""
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-box svg-icon feather-icon"
                        data-v-0a75af2f=""
                      >
                        <path
                          d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"
                          data-v-0a75af2f=""
                        ></path>
                        <polyline
                          points="2.32 6.16 12 11 21.68 6.16"
                          data-v-0a75af2f=""
                        ></polyline>
                        <line
                          x1="12"
                          y1="22.76"
                          x2="12"
                          y2="11"
                          data-v-0a75af2f=""
                        ></line>
                      </svg>
                      Estoque</span
                    >
                  </div></a
                >
              </div>
              <div class="grid-3" data-v-0a75af2f="">
                <div class="card" data-v-0a75af2f="">
                  <span data-v-0a75af2f=""
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user svg-icon"
                      data-v-0a75af2f=""
                    >
                      <path
                        d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                        data-v-0a75af2f=""
                      ></path>
                      <circle cx="12" cy="7" r="4" data-v-0a75af2f=""></circle>
                    </svg>
                    Pessoa</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      allItemRegitersActive: false,
      typeContactsActive: false,
      siproquimActive: false,
      pbmActive: false,
      microCompActive: false,
      FormFarmaActive: false,
      microCompStyle: "",
      allItemRegitersStyle: "",
      typeContactsStyle: "",
      siproquimsStyle: "",
      pbmStyle: "",
      FormFarmaStyle: "",
      CreateUsersStyle: "",
    };
  },

  mounted() {
    this.$store.dispatch("groupUsers/totalGroupUsers");
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout"); //remove auth
      // this.$router.push("/");

      location.reload(); //reload page
    },
    active() {
      if (this.allItemRegitersActive) {
        this.allItemRegitersActive = false;
        this.typeContactsActive = false;
        this.siproquimActive = false;
        this.pbmActive = false;
        this.microCompActive = false;
        this.FormFarmaActive = false;
        this.CreateUsersStyle = false;
      } else {
        this.allItemRegitersActive = true;
        this.typeContactsActive = true;
        this.siproquimActive = true;
        this.pbmActive = true;
        this.microCompActive = true;
        this.FormFarmaActive = true;
        this.CreateUsersStyle = true;
        this.microCompStyle = "microComp";
        this.allItemRegitersStyle = "allItemRegiters";
        this.typeContactsStyle = "typeContacts";
        this.siproquimsStyle = "siproquim";
        this.pbmStyle = "pbm";
        this.FormFarmaStyle = "FormFarma";
        this.CreateUsersStyle = "Users";
        this.microCompStyle = "microComp";
      }
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  background: #fff;
}

/* .allItemRegiters {
  display: flex;
  justify-content: flex-end;
  width: 190px;
  background-color: white;
  color: #2c3e50;
}

.allItemRegiters:hover {
  text-decoration: underline 1px #2c3e50 solid;
}

.typeContacts {
  display: flex;
  justify-content: flex-end;
  width: 190px;
  background-color: white;
  color: #2c3e50;
}

.typeContacts:hover {
  text-decoration: underline 1px #2c3e50 solid;
}

.siproquim {
  display: flex;
  justify-content: flex-end;
  width: 190px;
  background-color: white;
  color: #2c3e50;
}

.siproquim:hover {
  text-decoration: underline 1px #2c3e50 solid;
}

.pbm {
  display: flex;
  justify-content: flex-end;
  width: 190px;
  background-color: white;
  color: #2c3e50;
}

.pbm:hover {
  text-decoration: underline 1px #2c3e50 solid;
}

.microComp {
  display: flex;
  justify-content: flex-end;
  width: 190px;
  background-color: white;
  color: #2c3e50;
} */

.microComp:hover {
  text-decoration: underline 1px #2c3e50 solid;
}

.welcome {
  text-align: left;
  color: #2c3e50;
}

.logof {
  width: 70%;
  margin: 0 30px;
  border-bottom: 1px solid #edf2f9;
  padding: 30px 10px 30px 0;
}

.card {
  background: red;
  width: 30%;
  float: left;
  margin: 10px 10px 10px 0;
  min-height: 120px;
  padding: 20px 10px;
  background: #fff;
  box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -webkit-box-shadow: 0 3px 9px 0 rgb(169 184 200 / 15%);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  padding: 200;
  font-size: 21px;
  width: 100px;
}
.card span {
  text-align: left;
}

@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.fa-2x {
  font-size: 2em;
}

.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  overflow: visible;
}

.main-menu {
  background: white;
  border-right: 0px solid #dedede;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.main-menu img {
  display: none;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
  text-align: left;
  margin: 10px 0;
}

.main-menu li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

.main-menu:hover img {
  display: block;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}
.main-menu li:hover > a,
nav.main-menu li.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #5fa2db;
}
.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: local("Titillium WebLight"), local("TitilliumWeb-Light"),
    url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff)
      format("woff");
}

.welcome h2 {
  font-size: 2rem;
  color: #2c3e50;
}

.welcome h4 {
  font-size: 1.5rem;
  color: #2c3e50;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
</style>
